/* CSS */
$color_global: #758494;
$color_purple: #0E4377;
$color_green: #00CA95;
$color_blue: #11BFE3;
$color_red: #FB404B;
$color_white: #FBFBFB;

$font_family: 'Poppins', sans-serif;

::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: $color_purple;
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent)
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #565656;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #565656;
    opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #565656;
    opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #565656;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #565656;
}

::placeholder { /* Most modern browsers support this now. */
    color: #565656;
}

.alert-heading {
    font-size: 16px;
    text-decoration: underline;
}

.table {
    min-width: 100%;
}
table.table-fit {
    width: auto !important;
    table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
    width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
    width: auto !important;
}

html, body {
    height: 100%;
}

.m-b-0 {
    margin-bottom: 0;
}

body {
    overflow-x: hidden;
    color: $color_purple;
    background-color: #F5F5F5;
    font-family: $font_family;
    font-size: 14px;

    .background-body {
        background-image: url(https://demos.creative-tim.com/light-bootstrap-dashboard-pro-react/static/media/full-screen-image-3.ef9c8d65.jpg) !important;
        transition: all .25s ease-out 0s;
        position: fixed;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: 50%;
    }
    .background-body:before {
        opacity: 0.33;
        background: #000000;
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .background-body:after {
        opacity: 0.77;
        background: $color_purple;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0, $color_purple), color-stop(100%, #111111));
        background: -webkit-linear-gradient(top, $color_purple, #111111);
        background: -o-linear-gradient(top, $color_purple 0, #111111 100%);
        background: -webkit-gradient(linear, left top, left bottom, from($color_purple), to(#111111));
        background: linear-gradient(180deg, $color_purple 0, #111111);
        background-size: 150% 150%;
        z-index: 3;
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .container-main {
        padding: 0 15px;
        margin: 0 -15px;
        min-height: 100%;
        position: relative;
        z-index: 2;
    }
    #main-block {
        background-color: #F5F5F5;
        border-radius: 100px 0 0 0;
    }
    .container-main-other > div {
        min-height: 100%;
        width: 100%;
        position: absolute !important;
    }
    .container-main-other {
        .main-block-other {
            border-radius: 0 !important;

            .page-head {
                border-radius: 0 !important;
            }

            .dashboard-block > a {
                display: block;
                text-align: center;
                padding: 50px 15px;
                border-bottom: 5px solid $color_purple;
                background-color: $color_white;
                -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
                font-size: 20px;
            }
            .dashboard-partner-mynumbers {
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 15px;

                    li:first-child {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                    li {
                        margin-top: 5px;

                        span {
                            width: 100px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    /*
     * Global
     */
    .scrollup {
        background-color: $color_purple;
        color: white;
        z-index: 500000;
        right: 15px;
        bottom: 15px;
        width: 40px;
        height: 40px;
        position: fixed;
        display: none;
        font-size: 20px;
        text-align: center;
        line-height: 37px;
    }
    .loader-block {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        z-index: 100000000000;
    }
    .loader {
        margin: auto;
        margin-top: 15%;
        border: 16px solid #F3F3F3;
        border-radius: 50%;
        border-top: 16px solid $color_blue;
        border-right: 16px solid $color_green;
        border-bottom: 16px solid $color_red;
        border-left: 16px solid $color_purple;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .btn-primary {
        background-color: $color_blue !important;
        border-color: $color_blue !important;
    }
    .btn-danger {
        background-color: $color_red !important;
        border-color: $color_red !important;
    }
    .btn-success {
        background-color: $color_green !important;
        border-color: $color_green !important;
    }
    .btn-sm {
        //padding-top: 0 !important;
        //padding-bottom: 0 !important;
    }
    h1 {
        color: white;
        padding: 15px;
        margin: 0;
        font-size: 27px;
        text-transform: uppercase;
        text-align: center;

        i {
            margin-left: 25px;
        }
    }
    h1:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 15px;
        width: calc(100% - 30px);
        height: 1px;
        background-color: hsla(0, 0%, 100%, .3);
    }
    a, a:hover {
        text-decoration: none;
        color: $color_purple;
    }
    .vertical-align {
        vertical-align: -webkit-baseline-middle !important;
        vertical-align: baseline;
    }
    .no-padding {
        padding: 0 !important;
    }
    .no-margin {
        margin: 0 !important;
    }
    .padding15-lr {
        padding: 0 15px;
    }
    .marging15-b {
        margin-bottom: 15px;
    }
    .nodisplay {
        display: none !important;
    }
    .form-control::-webkit-input-placeholder,
    input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        color: #C1C8D0;
    }
    header {
        nav {
            ul {
                height: 100%;
                margin: 0;
                padding-left: 15px;
                padding-right: 15px;
                list-style: none;

                li {
                    a {
                        color: white;
                        display: block;
                        height: 100%;
                        padding: 0 20px;
                        padding-top: 18px;

                        i {
                            font-size: 25px !important;
                        }
                    }
                    a:hover {
                        color: white;
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }
                .header-search a {
                    color: white !important;
                    cursor: pointer;
                }
            }
            .dropdown {
                color: white !important;
                cursor: pointer;

                .dropdown-menu {
                    height: auto !important;
                    padding: 0 !important;
                    margin: 0 !important;
                    width: 200px;
                    border-radius: 0 !important;
                    top: 0 !important;
                    float: right !important;
                    right: 0 !important;
                    left: auto !important;
                    transform: translate3d(0px, 62px, 0px) !important;

                    a {
                        padding: 10px 20px;
                        font-weight: 500;
                        font-size: 13px;
                        color: #758494;
                        text-decoration: none;
                        text-align: left;
                        display: block;
                        border-left: 6px solid transparent;
                        position: relative;

                        i {
                            margin-right: 20px;
                            font-size: 18px !important;
                            min-width: 20px;
                            vertical-align: middle;
                        }
                    }
                    a:hover {
                        background-color: $color_purple;
                        color: white;
                    }
                }
            }
            .dropdown:hover > .dropdown-menu {
                display: block;
                transform: translate3d(0px, 62px, 0px) !important;
            }
        }
        nav > div {
            height: 100%;
        }
        .user_fullname {
            margin-top: 3px;
            margin-left: 10px;
            float: right;
        }
    }
    header + div {
        min-height: 100%;
        width: 100%;
        position: absolute !important;
    }
    main {
        padding: 30px;
        margin-bottom: 85px;
    }
    .navMain {
        display: flex;
        flex-flow: column;
        padding: 0 !important;
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
        box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
        padding-bottom: 30px !important;

        .nav-user {
            border-bottom: 1px solid hsla(0, 0%, 100%, .2);
            padding: 10px 30px;
            width: 100%;

            span {
                display: block;
                font-size: 20px;
                text-align: center;
                margin-bottom: 20px;
                color: white;
            }

            ul {
                clear: both;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    width: 33.333333%;
                    float: left;
                    text-align: center;

                    a {
                        color: white;
                        font-size: 26px;
                    }
                }
            }

        }
        .nav-main {
            z-index: 5;
            width: 100%;
        }
        #navMain {
            width: 100%;
            margin-top: 30px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                .nav-heading {
                    padding: 10px 30px;
                    font-weight: 700;
                    margin-top: 10px;
                    color: white;
                    display: block;
                    text-transform: uppercase;
                }
                .nav-version {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    span {
                        height: 52px;
                        margin: 10px 30px !important;
                        padding: 15px 0 !important;
                        text-align: center;
                        border: 1px solid white;

                        i {
                            margin-right: 20px;
                            font-size: 18px;
                            min-width: 20px;
                        }
                    }
                }
                a.active, a:hover, a:focus {
                    color: #FFFFFF;
                    opacity: 1;
                    background: hsla(0, 0%, 100%, .23);
                }
                a {
                    margin: 5px 15px 0;
                    border-radius: 10px;
                    color: #FFFFFF;
                    opacity: 0.80;
                    -webkit-transition: all .15s ease-in;
                    -o-transition: all .15s ease-in;
                    transition: all .15s ease-in;
                    padding: 10px 15px;
                    position: relative;
                    display: block;

                    i {
                        font-size: 20px;
                        margin-right: 15px;
                        line-height: 30px;
                        width: 30px;
                        text-align: center;
                    }
                }
                /* a {
                     padding: 10px 20px;
                     font-weight: 500;
                     font-size: 13px;
                     color: #758494;
                     text-decoration: none;
                     text-align: left;
                     display: block;
                     border-left: 6px solid transparent;
                     position: relative;

                     i {
                         margin-right: 20px;
                         font-size: 18px;
                         min-width: 20px;
                     }
                 }*/
            }
        }
    }
    footer {
        padding: 24px 0 11px;
        background-color: $color_white;
        position: absolute;
        bottom: 0;
        width: 100%;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), 0 0 0 1px rgba(63, 63, 68, .1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .05), 0 0 0 1px rgba(63, 63, 68, .1);
    }
    .page-head {
        background-color: $color_white;
        padding: 40px 60px;
        margin-bottom: 15px;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
        color: #777777;
        border-radius: 70px 0 0 0;

        h2 {
            margin: 0;
            font-size: 25px;
        }
        ul {
            margin: 0;
            list-style: none;

            li {
                display: inline-block;

                ul {
                    li {
                        display: block;
                    }
                }
            }
        }
        .btn {
            border: none !important;
        }
        .dropdown > a {
            font-size: 20px;
            cursor: pointer;
        }
        .dropdown-menu {
            right: 0 !important;
            left: auto !important;
            transform: translate3d(15px, 45px, 0) !important;
        }
    }
    .page-block {
        background-color: $color_white;
        padding: 35px 30px 35px 30px;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
        border-radius: 10px;

        &.page-block-sm {
            padding: 10px 30px 10px 30px !important;
        }

        form {
            label {
                vertical-align: baseline;
                vertical-align: -webkit-baseline-middle;
                margin: 0;
            }
        }
    }
    .page-block-margin-bottom {
        margin-bottom: 15px;
    }
    .modal-body .page-form {
        padding: 0;
        border: none;
    }
    .page-form {
        .filter-btn a {
            height: 100%;
            padding: 0 10px;

            i {
                margin-top: 6px;
            }
        }
        .filter-btn a:first-child {
            margin-right: 10px;
        }
        .form-group {
            position: relative;
        }
        .form-group .form-control {
            border-radius: 0;
            height: 50px;
            padding: 5px 15px 5px 50px;
            font-size: 16px;
        }
        .form-group span {
            position: absolute;
            top: 9px;
            bottom: 0;
            left: 10px;
            right: 0;
        }
        .form-control-feedback {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            display: block;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            pointer-events: none;
        }
        label {
            display: inline-block;
            width: 20%;
            font-size: 12px;
            margin-bottom: 2px;
            margin-top: 2px;
            text-transform: uppercase;
        }
        label sup {
            color: red;
            margin-left: 5px;
        }
        label:after {
            content: ":";
            display: inline-block;
            margin-left: 10px;
        }
        input:focus, select:focus, textarea:focus {
            border: 1px solid #AAAAAA;
            outline: 0 !important;
            color: #333333;
        }
        input, select, textarea {
            border: none !important;
            border-radius: 0px !important;
            color: #565656;
            padding: 2px 12px;
            height: 23px;
            width: 100%;
            font-size: 14px;
            line-height: 1.428571;
            background-color: rgba(14, 67, 119, 0.2);
            background-image: none;
            box-shadow: none !important;
        }
        input {
            width: 80%;
        }
        select {
            width: 80%;
        }
        select {
            padding: 2px 8px;
        }
        textarea {
            height: 150px;
            resize: none;
        }
        button {
            width: 100%;
            background-color: $color_purple !important;
            border-color: $color_purple !important;
            border-radius: 0;
        }
        button:hover, button:focus, button:active {
            background-color: #3C487F;
            border-color: #3C487F;
        }
        a.add-another-collection-widget {
            margin-left: 20%;
            margin-bottom: 15px;
        }
        a.add-another-collection-widget + div {
            margin-left: 20%;

            label {
                display: none;
            }
            input, select, textarea {
                width: 100%;
            }
        }
        a.add-another-collection-widget + div > div {
            margin-bottom: 5px;
        }
        a.add-another-collection-widget + div > div > div {
            display: flex;
        }
        a.add-another-collection-widget + div > div > div > div {
            padding-right: 10px;
        }
        a.add-another-collection-widget + div > div > div > div:last-child {
            padding-right: 0;
        }
        a.del-collection-widget {
            height: 34px;

            i {
                vertical-align: middle;
            }
        }
        .InputTypeSelectMultiple {
            height: 100px;
        }
        .InputTypeDate, .InputTypeDateTime {
            width: 70%;
            display: inline-block;
        }
        .InputTypeDate > select:first-child {
            margin-right: 2%;
        }
        .InputTypeDate > select:last-child {
            margin-left: 2%;
        }
        .InputTypeDate > select {
            width: 32%;
        }
        .InputTypeDateTime > div:first-child {
            width: 68%;
            display: inline-block;
            margin-right: 2%;
        }
        .InputTypeDateTime > div:last-child {
            width: 30%;
            display: inline-block;
        }
        .InputTypeDateTime > div:first-child > select:first-child {
            margin-right: 2%;
        }
        .InputTypeDateTime > div:first-child > select:last-child {
            margin-left: 2%;
        }
        .InputTypeDateTime > div:first-child > select {
            width: 32%;
        }
        .InputTypeDateTime > div:last-child > select:first-child {
            margin-right: 2%;
        }
        .InputTypeDateTime > div:last-child > select:last-child {
            margin-left: 2%;
        }
        .InputTypeDateTime > div:last-child > select {
            width: 47%;
        }
        .contactTheme {
            a {
                height: 100%;
                padding-top: 3px;

                i {
                    width: 11px;
                }
            }
        }
        .contactTheme > div {
            margin-top: 15px;
            display: flex;

            div {
                padding-right: 10px;
                width: 16.333333%;
            }
            div:last-child, .contactTheme_account {
                width: auto;
                padding: 0;
            }
            .contactTheme_account {
                margin-right: 10px;
            }
            .contactTheme_del {
                margin-left: 10px;
            }
            label {
                display: none;
            }
            input, textarea, select {
                width: 100%;
            }
        }
        #contactTheme_submit {
            height: 100%;
            vertical-align: top;
            border-radius: 4px;
        }
        #contactTheme_submit:focus {
            box-shadow: none !important;
        }
        #user_roles label,
        label[for="lessor_scale_webservice"],
        label[for="deal_by_staff_webservice"],
        #society_roles label,
        #society_update_roles label {
            width: 20%;
            float: left;
        }
        #user_society_roles label,
        label[for="user_society_locked"] {
            width: 50%;
            float: left;
        }
        #user_roles input,
        #society_roles input,
        #society_update_roles input,
        #deal_by_staff_webservice,
        #lessor_scale_webservice {
            width: 20px;
            margin-right: 60%;
        }
        #user_society_roles input,
        #user_society_locked {
            width: 20px;
            margin-right: 45%;
        }
        #society_contact_supervisor_select .contactTheme > div div:first-child,
        #deal_provider_select .contactTheme > div div:first-child,
        #society_staff_select .contactTheme > div div:first-child {
            width: 95%;
        }
        #society_contact_supervisor_select .contactTheme > div div:last-child,
        #deal_provider_select .contactTheme > div div:last-child,
        #society_staff_select .contactTheme > div div:last-child {
            width: 5%;
        }
        #society_contact_supervisor_select .contactTheme > div div:last-child a,
        #deal_provider_select .contactTheme > div div:last-child a,
        #society_staff_select .contactTheme > div div:last-child a {
            width: 100%;
        }
        #society_contact_supervisor_select label,
        #deal_provider_select label,
        #society_staff_select label {
            width: 100%;
        }
        fieldset {
            border: 1px groove #DDDDDD !important;
            margin: 0 0 1.5em 0 !important;
            -webkit-box-shadow: 0px 0px 0px 0px #000000;
            box-shadow: 0px 0px 0px 0px #000000;
        }
        legend {
            font-size: 1.2em !important;
            font-weight: bold !important;
            text-align: left !important;
            width: auto;
            padding: 0 10px;
            border-bottom: none;
        }
        #society_partner_special_conditions {
            input[type="checkbox"] {
                width: 5%;
            }
        }
    }
    .page-form form {
        margin: 0 -10px;

        .form_custom_widget {
            margin-bottom: 15px;
            padding: 0 15px;
        }
    }
    .page-form form > div > div {
        margin-bottom: 10px;
        padding: 0 15px;
    }
    .page-form form > div > div button {
        margin-bottom: -10px;
    }
    .page-form form > fieldset > div {
        margin-bottom: 10px;
        padding: 0 15px;
    }
    .col-margin-internal-left {
        padding: 0 0 0 15px;
    }
    table {
        margin-bottom: 0 !important;
    }
    table tbody .btn-danger {
        color: white !important;
    }
    .nav-tabs {
        border: 1px solid $color_purple;
        border-bottom: 5px solid $color_purple;
        margin-bottom: 35px !important;

        li {
            text-transform: capitalize;
            font-size: 14px;
            border-right: 1px solid $color_purple;
            text-align: center;

            .active {
                background-color: $color_purple;
                color: white;
            }
            a {
                display: block;
                padding: 5px 30px;
            }
        }
        li:last-child {
            border-right: 0;
        }
    }
    .tab-content .page-block {
        padding: 0;
        border: none;
        box-shadow: none !important;

        form {
            margin: 0;
        }
        form > div > div {
            padding: 0;
        }
    }
    .filter-paging {
        height: 50px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 15px;

            li {
                float: left;

                a {
                    display: block;
                    background-color: $color_purple;
                    color: white;
                    font-size: 16px;
                    padding: 5px 10px;
                    text-align: center;
                    margin: 0 5px;
                }
                a.active {
                    color: white;
                    background-color: #17A2B8;
                }
            }
        }
    }
    /* END - Global */

    /*
     * Search Form
     */
    .modal-backdrop {
        z-index: 1 !important;
    }
    #search-block {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $color_purple;
        cursor: crosshair;

        .modal-content {
            border-radius: 0;
            border: none;
            background-color: transparent;

            .modal-header {
                border: none !important;

                form {
                    width: 100%;

                    input {
                        display: block;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        background-color: transparent;
                        padding: 10px 15px;
                        outline: $color_purple;
                        text-transform: uppercase;
                        height: 50px;
                        padding-right: 65px;
                        width: 100%;
                        color: white;
                    }
                    input::-webkit-input-placeholder {
                        color: white;
                    }
                    /* WebKit, Blink, Edge */
                    input:-moz-placeholder {
                        color: white;
                    }
                    /* Mozilla Firefox 4 to 18 */
                    input::-moz-placeholder {
                        color: white;
                    }
                    /* Mozilla Firefox 19+ */
                    input:-ms-input-placeholder {
                        color: white;
                    }
                    /* Internet Explorer 10-11 */
                    input::-ms-input-placeholder {
                        color: white;
                    }
                    /* Microsoft Edge */

                    button {
                        height: 50px;
                        float: right;
                        width: 50px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        background-color: rgba(0, 0, 0, 0.1);
                        padding: 0;
                        color: white;
                        font-size: 25px;
                        margin-top: -50px;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }
            .modal-body {
                cursor: pointer;

                .search-title {
                    margin-top: 15px;

                    h2 {
                        font-size: 20px;
                        padding: 8px 15px;
                        background-color: rgba(0, 0, 0, 0.2);
                        color: white;
                        margin: 0;
                    }
                }
                .search-title:first-child {
                    margin-top: 0;
                }
                table {
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.1);
                    color: white;

                    td {
                        padding: 5px 15px !important;
                    }
                }
            }
        }
    }
    #dashboard-search {
        #search-input {
            display: block;
            border: 1px solid $color_purple;
            background-color: transparent;
            padding: 10px 15px;
            outline: #0E4377;
            text-transform: uppercase;
            height: 50px;
            padding-right: 65px;
            width: 100%;
        }
        #search-submit {
            height: 50px;
            float: right;
            width: 50px;
            border: 1px solid $color_purple;
            background-color: $color_purple;
            padding: 0;
            color: white;
            font-size: 25px;
            margin-top: -50px;
            outline: none;
            cursor: pointer;
        }
    }

    /*
     * Login Form
     */
    .main-public {
        .login-wrappper {
            margin-top: 30px !important;
        }
    }

    .login {
        margin-bottom: 30px;

        .center-block {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .login-wrappper {
            -webkit-box-shadow: 0 25px 30px -13px rgba(40, 40, 40, .4);
            box-shadow: 0 25px 30px -13px rgba(40, 40, 40, .4);
            border-radius: 10px;
            padding: 30px;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            background-color: white;
            margin-bottom: 30px;
            margin-top: 22vh;

            .login-bar {
                position: absolute;
                left: 15px;
                right: 15px;
                top: 0;
                margin: -98px 0 0;

                img {
                    width: 100%;
                }
            }
            .login-title {
                margin-bottom: 30px;

                h1 {
                    padding-top: 0;
                    color: $color_purple;
                    background-color: transparent;
                }
            }
        }
        .login-form {
            margin-bottom: 15px;
        }
        .login-form .form-group {
            position: relative;
        }
        .login-form .form-group .form-control {
            border: 1px solid #E3E3E3;
            border-radius: 4px;
            color: #565656;
            padding: 8px 12px;
            height: 40px;
            width: 100%;
            font-size: 14px;
            line-height: 1.428571;
            background-color: #FFFFFF;
            background-image: none;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        }
        .login-form .form-group span {
            position: absolute;
            top: 9px;
            bottom: 0;
            left: 10px;
            right: 0;
        }
        .form-group .form-control-feedback {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            display: block;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            pointer-events: none;
        }
        .fa-lg {
            font-size: 1.333333em;
            line-height: .75em;
            vertical-align: -15%;
        }

        .login_mandatoryinformation {
            text-align: center;
            padding: 15px;
            color: white;

            a, a:hover, a:focus {
                color: white;
            }
        }
    }
    /* END - Login Form */

    /*
     * GDPR
     */
    .gdpr_ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            span {
                font-weight: bold;
                width: 300px;
                display: inline-block;
            }
        }
    }

    /*
     * Dashboard
     */
    .widget-block {
        margin: 15px 0;

        h2 {
            font-size: 20px;
        }
    }
    .widget-action, .widget-action:hover, .widget-action:focus {
        background-color: red;
        color: white;
        display: block;
        margin-top: 15px;
        padding: 8px 15px;
        text-transform: uppercase;
        text-align: center;
    }
    .widget-action-50 {
        float: left;
        width: 48%;
    }
    .widget-action-50:last-child {
        margin-left: 4%;
    }
    .page-dashboard {
        width: 100%;

        h2 {
            color: white;
            display: block;
            padding: 8px 15px;
            text-transform: uppercase;
            background-color: $color_purple;
            font-size: 18px;
            margin: 0;
        }
        .widget-tasks {
            h2 {
                margin-bottom: 15px;
            }
            .tasks-block {
                max-height: 363px;
                overflow-y: auto;

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        margin-bottom: 20px !important;
                        padding: 12px 20px;
                        border: 1px solid #EAEBED;

                        .task-align {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            a {
                                width: fit-content;
                            }
                        }
                        .code {
                            margin-left: 28px;
                        }
                        .actions {
                            text-align: right;

                            a {
                                margin: 0 5px;
                            }
                        }
                        .task {
                            margin-left: 15px;
                            font-weight: bold;
                        }
                    }
                }
                .tasks-empty {
                    border: 2px solid #5E6DB3;
                    height: 348px;
                    margin-bottom: 0;
                    text-align: center;
                    padding: 150px 0;
                    font-size: 20px;
                }
            }
        }
    }
    .page-dashboard > div {
        margin-bottom: 15px;
    }
    .page-dashboard > div:last-child {
        margin-bottom: 0;
    }
    .dashboard-line-1 > div, .dashboard-line-1 > a > div {
        min-height: 120px;
        position: relative;

        i {
            color: white;
            position: absolute;
            right: 15px;
            bottom: 10px;
            font-size: 60px;
        }
        i.fa-briefcase {
            bottom: 5px;
        }
    }
    .dashboard-line-1 ul {
        list-style: none;
        padding: 15px;
        margin: 0;
        color: white;

        li {
            margin-bottom: 15px;

            span {
                display: inline-block;
                width: 160px;
                font-weight: bold;
            }
        }
    }
    .dashboard-line-1:nth-child(1) > a > div, .dashboard-line-1:nth-child(1) > a.widget-action {
        background-color: $color_purple;
    }
    .dashboard-line-1:nth-child(2) > a > div, .dashboard-line-1:nth-child(2) > a.widget-action {
        background-color: $color_green;
    }
    .dashboard-line-1:nth-child(3) > a > div, .dashboard-line-1:nth-child(3) > a.widget-action {
        background-color: $color_blue;
    }
    .dashboard-line-1:nth-child(4) > div, .dashboard-line-1:nth-child(4) > a.widget-action {
        background-color: $color_red;
    }
    /* END - Dashboard */

    /*
     * FullCalendar
     */
    .fc-button {
        border: none;
        background-color: $color_purple;
        text-shadow: none;
        background-image: none;
        color: white;
        text-transform: uppercase;
        border-radius: 0 !important;
        height: auto !important;
        padding: 8px 15px !important;
        font-size: 14px !important;
        outline: none;
    }
    .fc-state-active {
        background-color: #17A2B8;
    }
    .fc-head {
        background-color: $color_purple;
        color: white;
        border: none !important;

        .fc-row {
            border: none !important;
        }
        thead {
            border: none !important;
        }
        tr {
            border: none;

            th {
                padding: 5px;
                border: none;
                text-transform: capitalize;
            }
        }
    }
    .fc-axis, .fc-list-heading td {
        background-color: $color_purple !important;
        color: white;
        text-transform: capitalize;
    }

    #fullCalModalDesc{
        a.btnAction, a.btnAction:hover, a.btnAction:focus {
            color: white;
        }
        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 15px;
            }
        }
    }
    /* END FullCalendar */

    /*
     * Select2
     */
    .select2-container {
        width: 100% !important;
    }
    .select2-container--default.select2-container--focus .select2-selection--multiple {
        border: 1px solid #C1C8D0 !important;
    }
    .select2-container--default .select2-selection--multiple {
        border: 1px solid #C1C8D0 !important;
        border-radius: 0 !important;
    }
    /* END Select2 */

    /*
     * Activities
     */
    .calendar_activities {
        border-top: 5px solid $color_purple;
        margin-bottom: 15px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        h3 {
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 10px;
            padding: 5px 0;
            font-size: 17px;

            .report {
                color: $color_green;
            }
        }
    }
    .calendar_activities > div:first-child {
        ul {
            .type {
                background-color: $color_purple;
                color: white;
                padding: 5px 15px;
                text-transform: uppercase;
                text-align: center;
            }
            .date {
                text-align: center;
                padding: 5px 15px;
            }
        }
    }
    /* END Activities */
}

@media (max-width: 1919px) {
}

@media (max-width: 1679px) {
}

@media (max-width: 1599px) {
}

@media (max-width: 1439px) {
}

@media (max-width: 1365px) {
}

@media (max-width: 1279px) {
}

@media (max-width: 1199px) {
}

@media (max-width: 1023px) {
}

@media (max-width: 767px) {
}

@media (max-width: 424px) {
}

@media (max-width: 374px) {
}
