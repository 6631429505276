/* CSS */

::-webkit-scrollbar {
  width: 15px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #0E4377;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #565656;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #565656;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #565656;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #565656;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #565656;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #565656;
}

.alert-heading {
  font-size: 16px;
  text-decoration: underline;
}

.table {
  min-width: 100%;
}

table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}

table.table-fit thead th,
table.table-fit tfoot th {
  width: auto !important;
}

table.table-fit tbody td,
table.table-fit tfoot td {
  width: auto !important;
}

html,
body {
  height: 100%;
}

.m-b-0 {
  margin-bottom: 0;
}

body {
  overflow-x: hidden;
  color: #0E4377;
  background-color: #F5F5F5;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  /*
     * Global
     */
  /* END - Global */
  /*
     * Search Form
     */
  /*
     * Login Form
     */
  /* END - Login Form */
  /*
     * GDPR
     */
  /*
     * Dashboard
     */
  /* END - Dashboard */
  /*
     * FullCalendar
     */
  /* END FullCalendar */
  /*
     * Select2
     */
  /* END Select2 */
  /*
     * Activities
     */
  /* END Activities */
}

body .background-body {
  background-image: url(https://demos.creative-tim.com/light-bootstrap-dashboard-pro-react/static/media/full-screen-image-3.ef9c8d65.jpg) !important;
  transition: all .25s ease-out 0s;
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
}

body .background-body:before {
  opacity: 0.33;
  background: #000000;
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

body .background-body:after {
  opacity: 0.77;
  background: #0E4377;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0E4377), color-stop(100%, #111111));
  background: -webkit-linear-gradient(top, #0E4377, #111111);
  background: -o-linear-gradient(top, #0E4377 0, #111111 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#0E4377), to(#111111));
  background: linear-gradient(180deg, #0E4377 0, #111111);
  background-size: 150% 150%;
  z-index: 3;
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

body .container-main {
  padding: 0 15px;
  margin: 0 -15px;
  min-height: 100%;
  position: relative;
  z-index: 2;
}

body #main-block {
  background-color: #F5F5F5;
  border-radius: 100px 0 0 0;
}

body .container-main-other > div {
  min-height: 100%;
  width: 100%;
  position: absolute !important;
}

body .container-main-other .main-block-other {
  border-radius: 0 !important;
}

body .container-main-other .main-block-other .page-head {
  border-radius: 0 !important;
}

body .container-main-other .main-block-other .dashboard-block > a {
  display: block;
  text-align: center;
  padding: 50px 15px;
  border-bottom: 5px solid #0E4377;
  background-color: #FBFBFB;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  font-size: 20px;
}

body .container-main-other .main-block-other .dashboard-partner-mynumbers ul {
  list-style: none;
  margin: 0;
  padding: 15px;
}

body .container-main-other .main-block-other .dashboard-partner-mynumbers ul li:first-child {
  font-size: 20px;
  margin-bottom: 10px;
}

body .container-main-other .main-block-other .dashboard-partner-mynumbers ul li {
  margin-top: 5px;
}

body .container-main-other .main-block-other .dashboard-partner-mynumbers ul li span {
  width: 100px;
  display: inline-block;
}

body .scrollup {
  background-color: #0E4377;
  color: white;
  z-index: 500000;
  right: 15px;
  bottom: 15px;
  width: 40px;
  height: 40px;
  position: fixed;
  display: none;
  font-size: 20px;
  text-align: center;
  line-height: 37px;
}

body .loader-block {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100000000000;
}

body .loader {
  margin: auto;
  margin-top: 15%;
  border: 16px solid #F3F3F3;
  border-radius: 50%;
  border-top: 16px solid #11BFE3;
  border-right: 16px solid #00CA95;
  border-bottom: 16px solid #FB404B;
  border-left: 16px solid #0E4377;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body .btn-primary {
  background-color: #11BFE3 !important;
  border-color: #11BFE3 !important;
}

body .btn-danger {
  background-color: #FB404B !important;
  border-color: #FB404B !important;
}

body .btn-success {
  background-color: #00CA95 !important;
  border-color: #00CA95 !important;
}

body h1 {
  color: white;
  padding: 15px;
  margin: 0;
  font-size: 27px;
  text-transform: uppercase;
  text-align: center;
}

body h1 i {
  margin-left: 25px;
}

body h1:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

body a,
body a:hover {
  text-decoration: none;
  color: #0E4377;
}

body .vertical-align {
  vertical-align: -webkit-baseline-middle !important;
  vertical-align: baseline;
}

body .no-padding {
  padding: 0 !important;
}

body .no-margin {
  margin: 0 !important;
}

body .padding15-lr {
  padding: 0 15px;
}

body .marging15-b {
  margin-bottom: 15px;
}

body .nodisplay {
  display: none !important;
}

body .form-control::-webkit-input-placeholder,
body input::-webkit-input-placeholder,
body select::-webkit-input-placeholder,
body textarea::-webkit-input-placeholder {
  color: #C1C8D0;
}

body header nav ul {
  height: 100%;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  list-style: none;
}

body header nav ul li a {
  color: white;
  display: block;
  height: 100%;
  padding: 0 20px;
  padding-top: 18px;
}

body header nav ul li a i {
  font-size: 25px !important;
}

body header nav ul li a:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
}

body header nav ul .header-search a {
  color: white !important;
  cursor: pointer;
}

body header nav .dropdown {
  color: white !important;
  cursor: pointer;
}

body header nav .dropdown .dropdown-menu {
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 200px;
  border-radius: 0 !important;
  top: 0 !important;
  float: right !important;
  right: 0 !important;
  left: auto !important;
  transform: translate3d(0px, 62px, 0px) !important;
}

body header nav .dropdown .dropdown-menu a {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 13px;
  color: #758494;
  text-decoration: none;
  text-align: left;
  display: block;
  border-left: 6px solid transparent;
  position: relative;
}

body header nav .dropdown .dropdown-menu a i {
  margin-right: 20px;
  font-size: 18px !important;
  min-width: 20px;
  vertical-align: middle;
}

body header nav .dropdown .dropdown-menu a:hover {
  background-color: #0E4377;
  color: white;
}

body header nav .dropdown:hover > .dropdown-menu {
  display: block;
  transform: translate3d(0px, 62px, 0px) !important;
}

body header nav > div {
  height: 100%;
}

body header .user_fullname {
  margin-top: 3px;
  margin-left: 10px;
  float: right;
}

body header + div {
  min-height: 100%;
  width: 100%;
  position: absolute !important;
}

body main {
  padding: 30px;
  margin-bottom: 85px;
}

body .navMain {
  display: flex;
  flex-flow: column;
  padding: 0 !important;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  padding-bottom: 30px !important;
}

body .navMain .nav-user {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 30px;
  width: 100%;
}

body .navMain .nav-user span {
  display: block;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

body .navMain .nav-user ul {
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
}

body .navMain .nav-user ul li {
  width: 33.333333%;
  float: left;
  text-align: center;
}

body .navMain .nav-user ul li a {
  color: white;
  font-size: 26px;
}

body .navMain .nav-main {
  z-index: 5;
  width: 100%;
}

body .navMain #navMain {
  width: 100%;
  margin-top: 30px;
}

body .navMain #navMain ul {
  margin: 0;
  padding: 0;
  list-style: none;
  /* a {
                     padding: 10px 20px;
                     font-weight: 500;
                     font-size: 13px;
                     color: #758494;
                     text-decoration: none;
                     text-align: left;
                     display: block;
                     border-left: 6px solid transparent;
                     position: relative;

                     i {
                         margin-right: 20px;
                         font-size: 18px;
                         min-width: 20px;
                     }
                 }*/
}

body .navMain #navMain ul .nav-heading {
  padding: 10px 30px;
  font-weight: 700;
  margin-top: 10px;
  color: white;
  display: block;
  text-transform: uppercase;
}

body .navMain #navMain ul .nav-version {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

body .navMain #navMain ul .nav-version span {
  height: 52px;
  margin: 10px 30px !important;
  padding: 15px 0 !important;
  text-align: center;
  border: 1px solid white;
}

body .navMain #navMain ul .nav-version span i {
  margin-right: 20px;
  font-size: 18px;
  min-width: 20px;
}

body .navMain #navMain ul a.active,
body .navMain #navMain ul a:hover,
body .navMain #navMain ul a:focus {
  color: #FFFFFF;
  opacity: 1;
  background: rgba(255, 255, 255, 0.23);
}

body .navMain #navMain ul a {
  margin: 5px 15px 0;
  border-radius: 10px;
  color: #FFFFFF;
  opacity: 0.80;
  -webkit-transition: all .15s ease-in;
  -o-transition: all .15s ease-in;
  transition: all .15s ease-in;
  padding: 10px 15px;
  position: relative;
  display: block;
}

body .navMain #navMain ul a i {
  font-size: 20px;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

body footer {
  padding: 24px 0 11px;
  background-color: #FBFBFB;
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
}

body .page-head {
  background-color: #FBFBFB;
  padding: 40px 60px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  color: #777777;
  border-radius: 70px 0 0 0;
}

body .page-head h2 {
  margin: 0;
  font-size: 25px;
}

body .page-head ul {
  margin: 0;
  list-style: none;
}

body .page-head ul li {
  display: inline-block;
}

body .page-head ul li ul li {
  display: block;
}

body .page-head .btn {
  border: none !important;
}

body .page-head .dropdown > a {
  font-size: 20px;
  cursor: pointer;
}

body .page-head .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  transform: translate3d(15px, 45px, 0) !important;
}

body .page-block {
  background-color: #FBFBFB;
  padding: 35px 30px 35px 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  border-radius: 10px;
}

body .page-block.page-block-sm {
  padding: 10px 30px 10px 30px !important;
}

body .page-block form label {
  vertical-align: baseline;
  vertical-align: -webkit-baseline-middle;
  margin: 0;
}

body .page-block-margin-bottom {
  margin-bottom: 15px;
}

body .modal-body .page-form {
  padding: 0;
  border: none;
}

body .page-form .filter-btn a {
  height: 100%;
  padding: 0 10px;
}

body .page-form .filter-btn a i {
  margin-top: 6px;
}

body .page-form .filter-btn a:first-child {
  margin-right: 10px;
}

body .page-form .form-group {
  position: relative;
}

body .page-form .form-group .form-control {
  border-radius: 0;
  height: 50px;
  padding: 5px 15px 5px 50px;
  font-size: 16px;
}

body .page-form .form-group span {
  position: absolute;
  top: 9px;
  bottom: 0;
  left: 10px;
  right: 0;
}

body .page-form .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

body .page-form label {
  display: inline-block;
  width: 20%;
  font-size: 12px;
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: uppercase;
}

body .page-form label sup {
  color: red;
  margin-left: 5px;
}

body .page-form label:after {
  content: ":";
  display: inline-block;
  margin-left: 10px;
}

body .page-form input:focus,
body .page-form select:focus,
body .page-form textarea:focus {
  border: 1px solid #AAAAAA;
  outline: 0 !important;
  color: #333333;
}

body .page-form input,
body .page-form select,
body .page-form textarea {
  border: none !important;
  border-radius: 0px !important;
  color: #565656;
  padding: 2px 12px;
  height: 23px;
  width: 100%;
  font-size: 14px;
  line-height: 1.428571;
  background-color: rgba(14, 67, 119, 0.2);
  background-image: none;
  box-shadow: none !important;
}

body .page-form input {
  width: 80%;
}

body .page-form select {
  width: 80%;
}

body .page-form select {
  padding: 2px 8px;
}

body .page-form textarea {
  height: 150px;
  resize: none;
}

body .page-form button {
  width: 100%;
  background-color: #0E4377 !important;
  border-color: #0E4377 !important;
  border-radius: 0;
}

body .page-form button:hover,
body .page-form button:focus,
body .page-form button:active {
  background-color: #3C487F;
  border-color: #3C487F;
}

body .page-form a.add-another-collection-widget {
  margin-left: 20%;
  margin-bottom: 15px;
}

body .page-form a.add-another-collection-widget + div {
  margin-left: 20%;
}

body .page-form a.add-another-collection-widget + div label {
  display: none;
}

body .page-form a.add-another-collection-widget + div input,
body .page-form a.add-another-collection-widget + div select,
body .page-form a.add-another-collection-widget + div textarea {
  width: 100%;
}

body .page-form a.add-another-collection-widget + div > div {
  margin-bottom: 5px;
}

body .page-form a.add-another-collection-widget + div > div > div {
  display: flex;
}

body .page-form a.add-another-collection-widget + div > div > div > div {
  padding-right: 10px;
}

body .page-form a.add-another-collection-widget + div > div > div > div:last-child {
  padding-right: 0;
}

body .page-form a.del-collection-widget {
  height: 34px;
}

body .page-form a.del-collection-widget i {
  vertical-align: middle;
}

body .page-form .InputTypeSelectMultiple {
  height: 100px;
}

body .page-form .InputTypeDate,
body .page-form .InputTypeDateTime {
  width: 70%;
  display: inline-block;
}

body .page-form .InputTypeDate > select:first-child {
  margin-right: 2%;
}

body .page-form .InputTypeDate > select:last-child {
  margin-left: 2%;
}

body .page-form .InputTypeDate > select {
  width: 32%;
}

body .page-form .InputTypeDateTime > div:first-child {
  width: 68%;
  display: inline-block;
  margin-right: 2%;
}

body .page-form .InputTypeDateTime > div:last-child {
  width: 30%;
  display: inline-block;
}

body .page-form .InputTypeDateTime > div:first-child > select:first-child {
  margin-right: 2%;
}

body .page-form .InputTypeDateTime > div:first-child > select:last-child {
  margin-left: 2%;
}

body .page-form .InputTypeDateTime > div:first-child > select {
  width: 32%;
}

body .page-form .InputTypeDateTime > div:last-child > select:first-child {
  margin-right: 2%;
}

body .page-form .InputTypeDateTime > div:last-child > select:last-child {
  margin-left: 2%;
}

body .page-form .InputTypeDateTime > div:last-child > select {
  width: 47%;
}

body .page-form .contactTheme a {
  height: 100%;
  padding-top: 3px;
}

body .page-form .contactTheme a i {
  width: 11px;
}

body .page-form .contactTheme > div {
  margin-top: 15px;
  display: flex;
}

body .page-form .contactTheme > div div {
  padding-right: 10px;
  width: 16.333333%;
}

body .page-form .contactTheme > div div:last-child,
body .page-form .contactTheme > div .contactTheme_account {
  width: auto;
  padding: 0;
}

body .page-form .contactTheme > div .contactTheme_account {
  margin-right: 10px;
}

body .page-form .contactTheme > div .contactTheme_del {
  margin-left: 10px;
}

body .page-form .contactTheme > div label {
  display: none;
}

body .page-form .contactTheme > div input,
body .page-form .contactTheme > div textarea,
body .page-form .contactTheme > div select {
  width: 100%;
}

body .page-form #contactTheme_submit {
  height: 100%;
  vertical-align: top;
  border-radius: 4px;
}

body .page-form #contactTheme_submit:focus {
  box-shadow: none !important;
}

body .page-form #user_roles label,
body .page-form label[for="lessor_scale_webservice"],
body .page-form label[for="deal_by_staff_webservice"],
body .page-form #society_roles label,
body .page-form #society_update_roles label {
  width: 20%;
  float: left;
}

body .page-form #user_society_roles label,
body .page-form label[for="user_society_locked"] {
  width: 50%;
  float: left;
}

body .page-form #user_roles input,
body .page-form #society_roles input,
body .page-form #society_update_roles input,
body .page-form #deal_by_staff_webservice,
body .page-form #lessor_scale_webservice {
  width: 20px;
  margin-right: 60%;
}

body .page-form #user_society_roles input,
body .page-form #user_society_locked {
  width: 20px;
  margin-right: 45%;
}

body .page-form #society_contact_supervisor_select .contactTheme > div div:first-child,
body .page-form #deal_provider_select .contactTheme > div div:first-child,
body .page-form #society_staff_select .contactTheme > div div:first-child {
  width: 95%;
}

body .page-form #society_contact_supervisor_select .contactTheme > div div:last-child,
body .page-form #deal_provider_select .contactTheme > div div:last-child,
body .page-form #society_staff_select .contactTheme > div div:last-child {
  width: 5%;
}

body .page-form #society_contact_supervisor_select .contactTheme > div div:last-child a,
body .page-form #deal_provider_select .contactTheme > div div:last-child a,
body .page-form #society_staff_select .contactTheme > div div:last-child a {
  width: 100%;
}

body .page-form #society_contact_supervisor_select label,
body .page-form #deal_provider_select label,
body .page-form #society_staff_select label {
  width: 100%;
}

body .page-form fieldset {
  border: 1px groove #DDDDDD !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000000;
  box-shadow: 0px 0px 0px 0px #000000;
}

body .page-form legend {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

body .page-form #society_partner_special_conditions input[type="checkbox"] {
  width: 5%;
}

body .page-form form {
  margin: 0 -10px;
}

body .page-form form .form_custom_widget {
  margin-bottom: 15px;
  padding: 0 15px;
}

body .page-form form > div > div {
  margin-bottom: 10px;
  padding: 0 15px;
}

body .page-form form > div > div button {
  margin-bottom: -10px;
}

body .page-form form > fieldset > div {
  margin-bottom: 10px;
  padding: 0 15px;
}

body .col-margin-internal-left {
  padding: 0 0 0 15px;
}

body table {
  margin-bottom: 0 !important;
}

body table tbody .btn-danger {
  color: white !important;
}

body .nav-tabs {
  border: 1px solid #0E4377;
  border-bottom: 5px solid #0E4377;
  margin-bottom: 35px !important;
}

body .nav-tabs li {
  text-transform: capitalize;
  font-size: 14px;
  border-right: 1px solid #0E4377;
  text-align: center;
}

body .nav-tabs li .active {
  background-color: #0E4377;
  color: white;
}

body .nav-tabs li a {
  display: block;
  padding: 5px 30px;
}

body .nav-tabs li:last-child {
  border-right: 0;
}

body .tab-content .page-block {
  padding: 0;
  border: none;
  box-shadow: none !important;
}

body .tab-content .page-block form {
  margin: 0;
}

body .tab-content .page-block form > div > div {
  padding: 0;
}

body .filter-paging {
  height: 50px;
}

body .filter-paging ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

body .filter-paging ul li {
  float: left;
}

body .filter-paging ul li a {
  display: block;
  background-color: #0E4377;
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  text-align: center;
  margin: 0 5px;
}

body .filter-paging ul li a.active {
  color: white;
  background-color: #17A2B8;
}

body .modal-backdrop {
  z-index: 1 !important;
}

body #search-block {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0E4377;
  cursor: crosshair;
}

body #search-block .modal-content {
  border-radius: 0;
  border: none;
  background-color: transparent;
}

body #search-block .modal-content .modal-header {
  border: none !important;
}

body #search-block .modal-content .modal-header form {
  width: 100%;
  /* WebKit, Blink, Edge */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10-11 */
  /* Microsoft Edge */
}

body #search-block .modal-content .modal-header form input {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  padding: 10px 15px;
  outline: #0E4377;
  text-transform: uppercase;
  height: 50px;
  padding-right: 65px;
  width: 100%;
  color: white;
}

body #search-block .modal-content .modal-header form input::-webkit-input-placeholder {
  color: white;
}

body #search-block .modal-content .modal-header form input:-moz-placeholder {
  color: white;
}

body #search-block .modal-content .modal-header form input::-moz-placeholder {
  color: white;
}

body #search-block .modal-content .modal-header form input:-ms-input-placeholder {
  color: white;
}

body #search-block .modal-content .modal-header form input::-ms-input-placeholder {
  color: white;
}

body #search-block .modal-content .modal-header form button {
  height: 50px;
  float: right;
  width: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0;
  color: white;
  font-size: 25px;
  margin-top: -50px;
  outline: none;
  cursor: pointer;
}

body #search-block .modal-content .modal-body {
  cursor: pointer;
}

body #search-block .modal-content .modal-body .search-title {
  margin-top: 15px;
}

body #search-block .modal-content .modal-body .search-title h2 {
  font-size: 20px;
  padding: 8px 15px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  margin: 0;
}

body #search-block .modal-content .modal-body .search-title:first-child {
  margin-top: 0;
}

body #search-block .modal-content .modal-body table {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
}

body #search-block .modal-content .modal-body table td {
  padding: 5px 15px !important;
}

body #dashboard-search #search-input {
  display: block;
  border: 1px solid #0E4377;
  background-color: transparent;
  padding: 10px 15px;
  outline: #0E4377;
  text-transform: uppercase;
  height: 50px;
  padding-right: 65px;
  width: 100%;
}

body #dashboard-search #search-submit {
  height: 50px;
  float: right;
  width: 50px;
  border: 1px solid #0E4377;
  background-color: #0E4377;
  padding: 0;
  color: white;
  font-size: 25px;
  margin-top: -50px;
  outline: none;
  cursor: pointer;
}

body .main-public .login-wrappper {
  margin-top: 30px !important;
}

body .login {
  margin-bottom: 30px;
}

body .login .center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

body .login .login-wrappper {
  -webkit-box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
  box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
  border-radius: 10px;
  padding: 30px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  background-color: white;
  margin-bottom: 30px;
  margin-top: 22vh;
}

body .login .login-wrappper .login-bar {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 0;
  margin: -98px 0 0;
}

body .login .login-wrappper .login-bar img {
  width: 100%;
}

body .login .login-wrappper .login-title {
  margin-bottom: 30px;
}

body .login .login-wrappper .login-title h1 {
  padding-top: 0;
  color: #0E4377;
  background-color: transparent;
}

body .login .login-form {
  margin-bottom: 15px;
}

body .login .login-form .form-group {
  position: relative;
}

body .login .login-form .form-group .form-control {
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  line-height: 1.428571;
  background-color: #FFFFFF;
  background-image: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

body .login .login-form .form-group span {
  position: absolute;
  top: 9px;
  bottom: 0;
  left: 10px;
  right: 0;
}

body .login .form-group .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

body .login .fa-lg {
  font-size: 1.333333em;
  line-height: .75em;
  vertical-align: -15%;
}

body .login .login_mandatoryinformation {
  text-align: center;
  padding: 15px;
  color: white;
}

body .login .login_mandatoryinformation a,
body .login .login_mandatoryinformation a:hover,
body .login .login_mandatoryinformation a:focus {
  color: white;
}

body .gdpr_ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body .gdpr_ul li span {
  font-weight: bold;
  width: 300px;
  display: inline-block;
}

body .widget-block {
  margin: 15px 0;
}

body .widget-block h2 {
  font-size: 20px;
}

body .widget-action,
body .widget-action:hover,
body .widget-action:focus {
  background-color: red;
  color: white;
  display: block;
  margin-top: 15px;
  padding: 8px 15px;
  text-transform: uppercase;
  text-align: center;
}

body .widget-action-50 {
  float: left;
  width: 48%;
}

body .widget-action-50:last-child {
  margin-left: 4%;
}

body .page-dashboard {
  width: 100%;
}

body .page-dashboard h2 {
  color: white;
  display: block;
  padding: 8px 15px;
  text-transform: uppercase;
  background-color: #0E4377;
  font-size: 18px;
  margin: 0;
}

body .page-dashboard .widget-tasks h2 {
  margin-bottom: 15px;
}

body .page-dashboard .widget-tasks .tasks-block {
  max-height: 363px;
  overflow-y: auto;
}

body .page-dashboard .widget-tasks .tasks-block ul {
  padding: 0;
  margin: 0;
}

body .page-dashboard .widget-tasks .tasks-block ul li {
  margin-bottom: 20px !important;
  padding: 12px 20px;
  border: 1px solid #EAEBED;
}

body .page-dashboard .widget-tasks .tasks-block ul li .task-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body .page-dashboard .widget-tasks .tasks-block ul li .task-align a {
  width: fit-content;
}

body .page-dashboard .widget-tasks .tasks-block ul li .code {
  margin-left: 28px;
}

body .page-dashboard .widget-tasks .tasks-block ul li .actions {
  text-align: right;
}

body .page-dashboard .widget-tasks .tasks-block ul li .actions a {
  margin: 0 5px;
}

body .page-dashboard .widget-tasks .tasks-block ul li .task {
  margin-left: 15px;
  font-weight: bold;
}

body .page-dashboard .widget-tasks .tasks-block .tasks-empty {
  border: 2px solid #5E6DB3;
  height: 348px;
  margin-bottom: 0;
  text-align: center;
  padding: 150px 0;
  font-size: 20px;
}

body .page-dashboard > div {
  margin-bottom: 15px;
}

body .page-dashboard > div:last-child {
  margin-bottom: 0;
}

body .dashboard-line-1 > div,
body .dashboard-line-1 > a > div {
  min-height: 120px;
  position: relative;
}

body .dashboard-line-1 > div i,
body .dashboard-line-1 > a > div i {
  color: white;
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-size: 60px;
}

body .dashboard-line-1 > div i.fa-briefcase,
body .dashboard-line-1 > a > div i.fa-briefcase {
  bottom: 5px;
}

body .dashboard-line-1 ul {
  list-style: none;
  padding: 15px;
  margin: 0;
  color: white;
}

body .dashboard-line-1 ul li {
  margin-bottom: 15px;
}

body .dashboard-line-1 ul li span {
  display: inline-block;
  width: 160px;
  font-weight: bold;
}

body .dashboard-line-1:nth-child(1) > a > div,
body .dashboard-line-1:nth-child(1) > a.widget-action {
  background-color: #0E4377;
}

body .dashboard-line-1:nth-child(2) > a > div,
body .dashboard-line-1:nth-child(2) > a.widget-action {
  background-color: #00CA95;
}

body .dashboard-line-1:nth-child(3) > a > div,
body .dashboard-line-1:nth-child(3) > a.widget-action {
  background-color: #11BFE3;
}

body .dashboard-line-1:nth-child(4) > div,
body .dashboard-line-1:nth-child(4) > a.widget-action {
  background-color: #FB404B;
}

body .fc-button {
  border: none;
  background-color: #0E4377;
  text-shadow: none;
  background-image: none;
  color: white;
  text-transform: uppercase;
  border-radius: 0 !important;
  height: auto !important;
  padding: 8px 15px !important;
  font-size: 14px !important;
  outline: none;
}

body .fc-state-active {
  background-color: #17A2B8;
}

body .fc-head {
  background-color: #0E4377;
  color: white;
  border: none !important;
}

body .fc-head .fc-row {
  border: none !important;
}

body .fc-head thead {
  border: none !important;
}

body .fc-head tr {
  border: none;
}

body .fc-head tr th {
  padding: 5px;
  border: none;
  text-transform: capitalize;
}

body .fc-axis,
body .fc-list-heading td {
  background-color: #0E4377 !important;
  color: white;
  text-transform: capitalize;
}

body #fullCalModalDesc a.btnAction,
body #fullCalModalDesc a.btnAction:hover,
body #fullCalModalDesc a.btnAction:focus {
  color: white;
}

body #fullCalModalDesc ul {
  list-style: none;
  padding: 0;
}

body #fullCalModalDesc ul li {
  margin-bottom: 15px;
}

body .select2-container {
  width: 100% !important;
}

body .select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #C1C8D0 !important;
}

body .select2-container--default .select2-selection--multiple {
  border: 1px solid #C1C8D0 !important;
  border-radius: 0 !important;
}

body .calendar_activities {
  border-top: 5px solid #0E4377;
  margin-bottom: 15px;
}

body .calendar_activities ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body .calendar_activities h3 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 5px 0;
  font-size: 17px;
}

body .calendar_activities h3 .report {
  color: #00CA95;
}

body .calendar_activities > div:first-child ul .type {
  background-color: #0E4377;
  color: white;
  padding: 5px 15px;
  text-transform: uppercase;
  text-align: center;
}

body .calendar_activities > div:first-child ul .date {
  text-align: center;
  padding: 5px 15px;
}

